import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB_qGqS59G-D-NsW8c0sglBogSCOd0T9xQ",
    authDomain: "sarcasite.firebaseapp.com",
    projectId: "sarcasite",
    storageBucket: "sarcasite.appspot.com",
    messagingSenderId: "170474494452",
    appId: "1:170474494452:web:6026356566e1a69cf101a1",
    measurementId: "G-FWZK0R07DH"
  };
// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Use these for db & auth
const db = firebaseApp.firestore();


export default db;
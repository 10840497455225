import React, { useState } from "react";
import "./App.css";
import Feed from "./Feed";
import Sidebar from "./Sidebar";
import Widgets from "./Widgets";

function App() {
     const [isSidebarVisible, setIsSidebarVisible] = useState(true); // Sets sidebar visible by default   
     return (
       <div className="app">
         <div className="app-top-bar">
           <button className="hamburger-menu" onClick={() => setIsSidebarVisible(!isSidebarVisible)}>
             <img src="/sarcasite.svg" alt="Menu" style={{ width: '25px', height: '25px' }}/>
           </button>
         </div>
         {isSidebarVisible && <Sidebar />}
         <Feed />
         <Widgets />
       </div>
     );
   }
   

export default App;

import { Avatar } from "@mui/material";
import {
  ChatBubbleOutline,
  FavoriteBorder,
  Publish,
  Repeat,
  VerifiedUser,
} from "@mui/icons-material";
import React from "react";
import "./Post.css";

function Post({ displayName, username, verified, text, image, avatar, timestamp }) {

    // Convert the Unix timestamp to a Date object
    const date = new Date(timestamp * 1000); // Multiply by 1000 because JavaScript uses milliseconds

    // Format the date and time
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
  


  return (
    <div className="post">
      <div className="post__avatar">
        <Avatar src={avatar} />
      </div>
      <div className="post__body">
        <div className="post__header">
          <div className="post__headerText">
            <h3>
              {displayName}{" "}
              <span className="post__headerSpecial">
                {verified && <VerifiedUser className="post__badge" />} @
                {username} on {formattedDate} {formattedTime}
              </span>
            </h3>
          </div>
          <div className="post__headerDescription">
            <p>{text}</p>
          </div>
        </div>
        {image && <img src={image} alt="" className="post__image"/>}
        <div className="post__footer">
          <ChatBubbleOutline fontSize="small" />
          <Repeat fontSize="small" />
          <FavoriteBorder fontSize="small" />
          <Publish fontSize="small" />
        </div>
      </div>
    </div>
  );
}

export default Post;
import { Search } from "@mui/icons-material";
import React from "react";
import {
  TwitterTimelineEmbed,
  TwitterShareButton,
  TwitterTweetEmbed,
} from "react-twitter-embed";
import "./Widgets.css";

function Widgets() {
  return (
    <div className="widgets">
      <div className="widgets__input">
        <Search className="widgets__searchIcon" />
        <input placeholder="Search Sarcasite" type="text" />
      </div>

      <div className="widgets__widgetContainer">
        <h2>What's happening</h2>
        <TwitterTweetEmbed tweetId={"1582402914383196161"} />
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName="pablothee"
          options={{ height: 400 }}
        />
        <TwitterShareButton
          url={""}
          options={{ text: "" }}
        />
      </div>
    </div>
  );
}

export default Widgets;
import React, { useEffect, useState } from "react";
import "./Feed.css";
import Post from "./Post";
import TweetBox from "./TweetBox";
import db from "./firebase";

function Feed() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPostsAndUsers = async () => {
      const postsSnapshot = await db.collection("posts").orderBy('timestamp', 'desc').limit(20).get();
      const postsData = postsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      // Extract unique userIds
      const userIds = [...new Set(postsData.map(post => post.userId))];

      // Fetch user data in a single batch
      const usersData = await Promise.all(
        userIds.map(userId => db.collection("users").doc(userId).get())
      );

      // Map of userId to userData for quick lookup
      const usersMap = usersData.reduce((acc, userDoc) => {
        if (userDoc.exists) {
          acc[userDoc.id] = userDoc.data();
        }
        return acc;
      }, {});

      // Combine posts with user data
      const combinedData = postsData.map(post => ({
        ...post,
        ...usersMap[post.userId] // Map user data to each post
      })).sort((a, b) => b.timestamp - a.timestamp); // Sorting in descending order

      setPosts(combinedData);
    };

    fetchPostsAndUsers();
  }, []);

  return (
    <div className="feed">
      <div className="feed__header">
        <h2>Everything</h2>
      </div>
      <TweetBox />
      {posts.map((post) => (
        <Post
          key={post.id}
          displayName={post.displayName || ''}
          username={post.username || ''}
          verified={post.verified || false}
          text={post.text}
          avatar={post.avatar || ''}
          image={post.image}
          timestamp={post.timestamp}
        />
      ))}
    </div>
  );
}

export default Feed;
